import * as CONST from '../common/const';
import createReducer from './createReducer';
import set from "lodash/fp/set";

export const initialState = {
    isLoading: true,
    error: null,
    searchItems: [],
    groupNumberItems: [{value: 'all', label: 'ALL'}],
    userItems: [{value: 'all', label: 'ALL'}],
    teamItems: [{value: 'all', label: 'ALL'}],
    downloading: false,
    isfetchingData: true,
    downloadURL: null,
    disableDataFetchButton: false
};

// Function to find the index of an object by a specific attribute
const findIndexByAttribute = (list, attributeName, value) => {
    return list.findIndex(item => item[attributeName] === value);
};

export default createReducer(initialState, {
    [CONST.LANDING_PAGE_SEARCH_START]: state => ({
        ...state,
        isfetchingData: true,
        error: null,
    }),

    [CONST.LANDING_PAGE_SEARCH_SUCCESS]: (state, action) => ({
        ...state,
        searchItems: action.payload.items,
        groupNumberItems: action.payload.groupNumberItems,
        userItems: action.payload.userItems,
        teamItems: action.payload.teamItems,
        isLoading: false,
        disableDataFetchButton: true,
        isfetchingData: false,
        error: null,
    }),
    
    [CONST.LANDING_PAGE_SEARCH_FAILURE]: (state, action) => ({
        ...state,
        isLoading: true,
        isfetchingData: false,
        disableDataFetchButton: false,
        error: action.payload,
    }),

    [CONST.RESET_FETCH_BUTTON_VISIBILITY]: (state, action) => ({
        ...state,
        disableDataFetchButton: false
    }),

    [CONST.LANDING_PAGE_POST_COMMENT_START]: state => ({
        ...state,
        error: null,
    }),

    [CONST.LANDING_PAGE_POST_COMMENT_SUCCESS]: (state, action) => {
        const index = findIndexByAttribute(state.searchItems, 'id', action.payload.newVal.id);
        const newVal = action.payload.newVal;
        newVal.isCommentAvailable = action.payload.isCommentAvailable;
        newVal.commentList = action.payload.comments;
        // blanking the comments required column after successful posting. New comment becomes the latest comment
        newVal.newComment = ""
        const searchItems = set(`[${index}]`, newVal, state.searchItems);

        return ({
            ...state,
            searchItems: searchItems,
            error: null,
        });
    },

    [CONST.LANDING_PAGE_POST_COMMENT_FAILURE]: (state, action) => ({
        ...state,
        error: action.payload,
    }),

    [CONST.EXPORT_TABLE_START]: (state, action) => ({
        ...state,
        downloading: true,
    }),

    [CONST.EXPORT_TABLE_SUCCESS]: (state, action) => ({
        ...state,
        downloading: false,
        downloadURL: action.downloadURL,
    }),

    [CONST.EXPORT_TABLE_FAILURE]: (state, action) => ({
        ...state,
        downloading: false,
        downloadURL: null,
    }),

    [CONST.EXPORT_TABLE_RESET]: (state, action) => ({
        ...state,
        downloading: false,
        downloadURL: null,
    }),
});

