import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from 'pages/landingPage';

/**
 * @returns {Element}
 * @constructor
 */
function ITCReportingApp() {
    return (
      <Routes>
          <Route path="/index.html" element={<LandingPage/>} />
          <Route path="/" element={<Navigate to="/index.html" />}/>
      </Routes>
    );
}

ITCReportingApp.propTypes = {};

ITCReportingApp.defaultProps = {};

export default ITCReportingApp;
