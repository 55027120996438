import React from 'react';
 
function ITCTrendingReportRestrict() {
  return <div>This website is restricted to fintech-ica team only (https://permissions.amazon.com/a/team/FinTech-ICA).</div>;
}
 
ITCTrendingReportRestrict.propTypes = {};
 
ITCTrendingReportRestrict.defaultProps = {};
 
export default ITCTrendingReportRestrict;