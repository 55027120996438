import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AppLayout } from '@amzn/awsui-components-react/polaris';
import ITCReportingApp from './ITCReportingApp';
import ITCTrendingReportRestrict from './ITCTrendingReportRestrict';
import { ensureAuthenticated } from 'utils/auth';
import { loginSuccess, loginFailure } from 'actions';

import { Provider } from 'react-redux';

import configureStore from './store';
 
const store = configureStore({});

function startITCReportingApp() {
    
    // Authenticate user
    ensureAuthenticated()
    .then((auth) => {
        store.dispatch(loginSuccess(auth));
        const container = document.getElementById('root');
        const root = createRoot(container);
        root.render(
            <Provider store={store}>
                <BrowserRouter>
                    <div>
                        <AppLayout
                            content={<ITCReportingApp />}
                            contentType="default"
                            toolsHide
                            navigationHide={true}
                        />
                    </div>
                </BrowserRouter>
            </Provider>
        );
        document.getElementById('root')
    })
    .catch((e) => {
        console.log('ensureAuthenticated threw an exception: ', e);
        store.dispatch(loginFailure(e));
        const container = document.getElementById('root');
        const root = createRoot(container);
        root.render(
            <BrowserRouter>
                <ITCTrendingReportRestrict />
            </BrowserRouter>
        );
    });
}
 
startITCReportingApp();
