import { combineReducers } from 'redux';
import auth from './auth';
import landingPage from './landingPage';
import { reducer as formReducer } from 'redux-form';
 
export default combineReducers({
    auth,
    landingPage,
    // function that tells how to update the Redux store based on changes coming from the application; those changes are described by Redux actions
    form: formReducer
});