import * as CONST from '../common/const';
import api from '../utils/api';

export const searchStart = () => ({
    type: CONST.LANDING_PAGE_SEARCH_START,
});

export const searchSuccess = (items = []) => ({
    type: CONST.LANDING_PAGE_SEARCH_SUCCESS,
    payload: items,
});

export const searchFailure = (error = null) => ({
    type: CONST.LANDING_PAGE_SEARCH_FAILURE,
    globalError: error,
});

export const resetFetchButtonVisibilty = () => ({
    type: CONST.RESET_FETCH_BUTTON_VISIBILITY
});

export const myDashboardSearch = parameters => async (dispatch, getState) => {
    console.log("parameters", parameters);

    dispatch(searchStart());
    try {
        const result = {};

        parameters.start = "";
        const records = {AgreementObjectList: []};
        let response = {start: ""};
        do {
            console.log("parameters", parameters);

            response = await api.getTrendingReportNew(parameters);
            if(response.AgreementObjectList) {
                for(let agreementObj of response.AgreementObjectList) {
                    records.AgreementObjectList.push(agreementObj);
                }
            }
            parameters.start = response.start || "";
            
        } while(response && response.hasOwnProperty('start') && response.start);

        const dbItems = (records == null)? [] : records.AgreementObjectList || []; 
        result.items = dbItems.map((item) => {
            return {...item, newComment: ""};
        });

        // sorting agreements lexographically
        result.items.sort(
            function(a, b) {
                var agreementNumberA = a.agreementNumber.toUpperCase(); // ignore upper and lowercase
                var agreementNumberB = b.agreementNumber.toUpperCase(); // ignore upper and lowercase
                // condition to add empty string to end of list
                if(!agreementNumberA) {
                    return 1;
                }
                // condition to add empty string to end of list
                if(!agreementNumberB) {
                    return -1;
                }
                if (agreementNumberA < agreementNumberB) {
                    return -1;
                }
                if (agreementNumberA > agreementNumberB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
        );

        const groupNumberSet = new Set();
        const userSet        = new Set();
        const teamSet        = new Set();

        for (var item, i = 0; (result.items) && (item = result.items[i++]);) {
            var groupNumber = item.groupNumber; 
            addToSet(groupNumber, groupNumberSet);

            var owner = item.owner;
            addToSet(owner, userSet);

            var assignee = item.assignee;
            addToSet(assignee, userSet);

            var ownerTeam = item.ownerTeam;
            addToSet(ownerTeam, teamSet);

            var assigneeTeam = item.assigneeTeam;
            addToSet(assigneeTeam, teamSet);
        }

        const groupNumberItems = [...groupNumberSet];
        result.groupNumberItems = [{value: 'all', label: 'ALL'}].concat(generateDropdownOptions(groupNumberItems));
        result.groupNumberItems.sort(
            function(a, b) {
                var groupA = a.value.toUpperCase(); // ignore upper and lowercase
                var groupB = b.value.toUpperCase(); // ignore upper and lowercase
                if (groupA < groupB) {
                    return -1;
                }
                if (groupA > groupB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
        );

        const userItems = [...userSet];
        result.userItems = [{value: 'all', label: 'ALL'}].concat(generateDropdownOptions(userItems));

        const teamItems = [...teamSet];
        result.teamItems = [{value: 'all', label: 'ALL'}].concat(generateDropdownOptions(teamItems));

        dispatch(searchSuccess(result));
    } catch (e) {
        dispatch(searchFailure(e));
    }
};

let addToSet = (str = "", set = {}) => {
    if(str && !set.has(str)) {
        set.add(str);
    }
};

function generateDropdownOptions (items = []) {
    return items.map(item => { return {label: item, value: item}});
}

export const postCommentStart = () => ({
    type: CONST.LANDING_PAGE_POST_COMMENT_START
});

export const postCommentSuccess = (newVal = {}, comments = [], isCommentAvailable = 0) => ({
    type: CONST.LANDING_PAGE_POST_COMMENT_SUCCESS,
    payload: {
        newVal: newVal,
        comments: comments,
        isCommentAvailable: isCommentAvailable
    }
});

export const postCommentFailure = (error = null) => ({
    type: CONST.LANDING_PAGE_POST_COMMENT_FAILURE,
    globalError: error,
});

export const triggerUpdateData = (newVal) => async (dispatch, getState) => {
    const { username } = getState().auth;
    dispatch(postCommentStart());
    try {
        const newComment = createNewComment(username, newVal);
        console.log(newComment);
        const payload = {
            agreement: newVal,
            comment: newComment
        }
        console.log(payload);
        const result = await api.postCommentData(payload);

        dispatch(postCommentSuccess(newVal, result.commentList, result.isCommentAvailable));
        console.log("result.comments", result.commentList);
    } catch (e) {
        dispatch(postCommentFailure(e));
    }
};

const createNewComment = (username = "", row = {}) => {
    console.log("createNewComment", row);
    const timestamp = Date.now();
    const ts = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(timestamp);

    return {
        user: username,
        content: row.newComment,
        ts: ts
    };
};

// Download Report Actions
export const downloadReportStart = () => ({
    type: CONST.EXPORT_TABLE_START,
});

export const downloadReportSuccess = fileUrl => {
    return ({
        type: CONST.EXPORT_TABLE_SUCCESS,
        downloadURL: fileUrl,
    });
}

export const downloadReportFailure = (error = null) => ({
    type: CONST.EXPORT_TABLE_FAILURE,
    globalError: error,
});

export const downloadReport = (path, filter) => async (dispatch) => {
    let payload = {
        period: path,
        duration: '13m',
        ...(Object.keys(filter).length === 0? {}: {filters: filter})
    }
    console.log(payload);
    dispatch(downloadReportStart());
    try {
        const result = await api.exportTrendingReport(payload);
        dispatch(downloadReportSuccess(result.reportURL));
        console.log(result.reportURL);
    } catch (e) {
        dispatch(downloadReportFailure(e));
    }
};

export const downloadReportReset = () => ({
    type: CONST.EXPORT_TABLE_RESET,
});