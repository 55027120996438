import { ensureAuthenticated } from '../utils/auth';
import Logger from '../utils/logger';
 
let baseUrl = '/api'
const environment = process.env.NODE_ENV;
// Common Authentication logic.
function authenticate() {
    return ensureAuthenticated();
}
 
async function callApi(method, endpoint, body = null, headers = {}) {
    console.log("callApi");
    const auth = await authenticate();
    const session = auth.getSignInUserSession();
    const jwtToken = session.getIdToken().getJwtToken();
    baseUrl = process.env.BUSINESS_LOGIC_API;
    
    const url = `${baseUrl}${endpoint}`;
    
    // 'https://u6e7hu93e1.execute-api.us-west-2.amazonaws.com/test' //
    console.log("BASE URL " + url);
    Logger.logInfo(url);
    
    async function checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
    
        if (response.status === 427) {
            const responseBody = await response.json();
            const error = new Error(responseBody.message);
            error.response = response;
            throw error;
        } else if (response.status >= 300 && response.status < 500) {
            const error = new Error(response.statusText)
            error.response = response;
            throw error;
        } else {
            const error = new Error(CONSTANT.GENERIC_ERROR_MESSAGE);
            error.response = response;
            throw error;
        }
    }
    
    function parseJSON(response) {
        return response.json();
    }
    
    const response = await fetch(url, {
        method,
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            ...headers,
            Authorization: jwtToken,
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: method === 'GET' ? null : JSON.stringify(body),
    });

    await checkStatus(response);
    const result = await parseJSON(response);
    
    Logger.logInfo(`HTTP ${method} ${endpoint} = ${result}`);
    return result;
}

const API = {
    async getTrendingReport(payload) {
    
        Logger.logInfo(payload);
        const result = await callApi('POST', '/getTrendingReport', payload);
    
        return result;
    },

    async getTrendingReportNew(payload) {
    
        Logger.logInfo(payload);
        const result = await callApi('POST', '/getTrendingReportNew', payload);
    
        return result;
    },

    // REMOVE REMOVE REMOVE
    async exportTrendingReportOld(path) {
        
        Logger.logInfo(path);
        // var url = '/exportTrendingReport?period=JUN-2020&duration=13m';
        // Logger.logInfo(url);
        const result = await callApi('GET', path);
    
        return result;
    },

    async exportTrendingReport(payload) {

        Logger.logInfo(payload);
        const result = await callApi('POST', '/exportTrendingReport', payload);
    
        return result;
    },

    async postCommentData(payload) {

        Logger.logInfo(payload);
        const result = await callApi('POST', '/postComment', payload);
    
        return result;
    },
    
    async downloadSourceData(path) {
        const result = await callApi(
        'GET',
        path,
        );
        Logger.logInfo(`downloadTemplate() = ${result}`);
        return result;
    },

    async getTPData() {
    
        Logger.logInfo("getTPData1");
        const result = await callApi('POST', '', {"Operation": "com.amazon.itctrendingreportservice#GetTrendingReport",
        "Service": "com.amazon.itctrendingreportservice#ITCTrendingReportService",
        "Input": ""}, headers);
        console.log(result);
        return result;
    },
};
 
export default API;