import React from 'react';
import './ICBanner.css';
import {
    Button,
    Box,
    SpaceBetween,
} from '@amzn/awsui-components-react/polaris';
 
function ICBanner(props) {
    const { bannerText } = props;
    return (
        <>
            <SpaceBetween direction="vertical" size="xxs">
            <Box float="right">
                <Button
                    iconName="external"
                    href="https://t.corp.amazon.com/create/templates/9f8dc26f-bfc1-403d-b53f-92274a870ab6"
                    iconAlign="right"
                >
                    Create Ticket
                </Button>
            </Box>
            <div className="ICIntegrationBanner">
                <h3>{bannerText}</h3>
            </div>
            </SpaceBetween>
        </>
    );
}
 
export default ICBanner;