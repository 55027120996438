import React from 'react';
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Header from "@amzn/awsui-components-react/polaris/header";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import 'react-data-grid/lib/styles.css';
import DataGrid from 'react-data-grid';
import {textEditor} from 'react-data-grid';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../../../actions';
import FormProvider from "./FormProvider"
import { withRouter } from '../../../utils/withRouter';
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";
import styles from './DetailDataView.scss'
import Container from "@amzn/awsui-components-react/polaris/container";
import DetailCommentsView from "./DetailCommentsView"
import {value} from "lodash/seq";


const formatAmount = (amount) => {
  const absValue = Math.abs(amount);
  const returnString = absValue.toLocaleString(undefined, {maximumFractionDigits:0});
  return amount < 0 ? '(' + returnString + ')' : returnString;
};

const renderHeaderCellWithText = ( props ) => {
  return (
        <Box textAlign="center" fontSize={"heading-s"} padding={"xs"}>{props.column.name}</Box>
  );
};

const renderHeaderCellWithCheckBox = ( props ) => {
  return (
      <>
        {renderHeaderCellWithText(props)}
        <Checkbox
            checked={props.column.checked}
            onChange={props.column.run}
        >
          {props.column.content}
        </Checkbox>
      </>
  );
};



const renderCell = ( props ) => {
  let cellValue;
  let className = '';
  let columnName = props.column.key;
  let dataType = props.column.dataType;
  let data = props.row[columnName];
  
  switch (dataType) {
    case 'indexNumber':
      cellValue = props.rowIdx + 1;
      break;
    case 'calculation':
      cellValue = formatAmount(data) + ' %';
      className = 'text-align-right';
      break;
    case 'monthlyAmount':
      let accessor = columnName.split(".");
      cellValue = formatAmount(props.row[accessor[0]][accessor[1]]);
      className = 'text-align-right';
      break;
    case 'latestComment':
      let length = props.row.commentList? props.row.commentList.length: 0;
      cellValue = props.row.commentList && length > 0 ? props.row.commentList[length-1]['content']: '';
      break;
    default:
      cellValue = props.row[columnName];
  }

  if(props.row.isCommentRequired && props.row.isCommentRequired > 0
    && (!props.row.isCommentAvailable || props.row.isCommentAvailable < 1)) {
      className = className + " highlight-container height-percent";
  }

  return (
    <div className={`data-grid-cell ${className}`}>
      {cellValue}
    </div>
  );
};



class DetailDataView extends React.Component {
  propertyColumnItems = [
    {
      name: '#',
      key: 'index',
      width: 20,
      editable: false,
      dataType: 'indexNumber',
      renderCell: renderCell,
      renderHeaderCell: renderHeaderCellWithText,
      frozen: true
    },
    {
      name: 'Agreement Number',
      key: 'agreementNumber',
      width: 190,
      editable: false,
      dataType: 'text',
      renderCell: renderCell,
      renderHeaderCell:  this.renderHeaderCellWithInputFilter.bind(this),
      headerCellClass: styles.filterColumn,
      frozen: true
    },
    {
      name: 'Type',
      key: 'type',
      width: 100,
      editable: false,
      dataType: 'text',
      renderCell: renderCell,
      renderHeaderCell: renderHeaderCellWithText,
      frozen: false
    },
    {
      name: 'Group Number',
      key: 'groupNumber',
      width: 110,
      editable: false,
      renderHeaderCell:  this.renderHeaderCellWithInputFilter.bind(this),
      headerCellClass: styles.filterColumn,
      dataType: 'text',
      renderCell: renderCell,
      frozen: false
    },
    {
      name: 'Tax File Name',
      key: 'taxFileName',
      width: 350,
      editable: false,
      renderHeaderCell:  this.renderHeaderCellWithInputFilter.bind(this),
      headerCellClass: styles.filterColumn,
      resizable: true,
      dataType: 'text',
      renderCell: renderCell,
      frozen: true
    },
    {
      name: 'Provider',
      key: 'provider',
      width: 100,
      editable: false,
      renderHeaderCell:  this.renderHeaderCellWithInputFilter.bind(this),
      headerCellClass: styles.filterColumn,
      resizable: true,
      dataType: 'text',
      renderCell: renderCell,
      frozen: false
    },
    {
      name: 'Recipient',
      key: 'recipient',
      width: 100,
      editable: false,
      renderHeaderCell:  this.renderHeaderCellWithInputFilter.bind(this),
      headerCellClass: styles.filterColumn,
      resizable: true,
      dataType: 'text',
      renderCell: renderCell,
      frozen: false
    },
    {
      name: 'Owner',
      key: 'owner',
      width: 100,
      editable: false,
      renderHeaderCell:  this.renderHeaderCellWithInputFilter.bind(this),
      headerCellClass: styles.filterColumn,
      resizable: true,
      dataType: 'text',
      renderCell: renderCell,
      frozen: false
    },
    {
      name: 'Assignee',
      key: 'assignee',
      width: 100,
      editable: false,
      renderHeaderCell:  this.renderHeaderCellWithInputFilter.bind(this),
      headerCellClass: styles.filterColumn,
      resizable: true,
      dataType: 'text',
      renderCell: renderCell,
      frozen: false
    },
    {
      name: 'Entity',
      key: 'entity',
      width: 100,
      editable: false,
      renderHeaderCell:  this.renderHeaderCellWithInputFilter.bind(this),
      headerCellClass: styles.filterColumn,
      resizable: true,
      dataType: 'text',
      renderCell: renderCell,
      frozen: false
    },
    {
      name: 'Transaction Currency',
      key: 'transactionCurrency',
      width: 110,
      editable: false,
      renderHeaderCell:  this.renderHeaderCellWithInputFilter.bind(this),
      headerCellClass: styles.filterColumn,
      dataType: 'text',
      renderCell: renderCell,
      frozen: false
    },
    {
      name: 'Account',
      key: 'account',
      width: 100,
      editable: false,
      renderHeaderCell:  this.renderHeaderCellWithInputFilter.bind(this),
      headerCellClass: styles.filterColumn,
      dataType: 'text',
      renderCell: renderCell,
      frozen: false
    },
    {
      name: 'Effective To',
      key: 'effectiveDate',
      width: 100,
      editable: false,
      renderHeaderCell:  this.renderHeaderCellWithInputFilter.bind(this),
      headerCellClass: styles.filterColumn,
      dataType: 'text',
      renderCell: renderCell,
      frozen: false
    }
  ];

  aggregateColumnItems = [
    {
      name: 'M/M Variance (%)',
      key: 'mmVar',
      width: 140,
      editable: false,
      dataType: 'calculation',
      renderCell: renderCell,
      renderHeaderCell: renderHeaderCellWithText,
      frozen: false
    },
    {
      name: 'Curr Mo vs 3 Mo Avg. (%)',
      key: 'currM3MAvg',
      width: 185,
      editable: false,
      dataType: 'calculation',
      renderCell: renderCell,
      renderHeaderCell: renderHeaderCellWithText,
      frozen: false
    }
  ];

  static propTypes = {
    showDashboardFilter: PropTypes.bool,
    filterPeriod: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    }),
    filterCurrency: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    }),
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      source: PropTypes.string,
      groupNumber: PropTypes.string,
      taxFileName: PropTypes.string,
      agreementNumber: PropTypes.string,
      provider: PropTypes.string,
      recipient: PropTypes.string,
      ownerTeam: PropTypes.string,
      owner: PropTypes.string,
      assigneeTeam: PropTypes.string,
      assignee: PropTypes.string,
      entity: PropTypes.string,
      transactionCurrency: PropTypes.string,
      account: PropTypes.string,
      prior12: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior11: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior10: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior09: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior08: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior07: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior06: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior05: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior04: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior03: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior02: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior01: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior00: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      effectiveDate: PropTypes.string,
      mmVar: PropTypes.number,
      currM3MAvg: PropTypes.number,
      commentList: PropTypes.arrayOf(PropTypes.shape({
        user: PropTypes.string,
        content: PropTypes.string,
        ts: PropTypes.string,
      })),
      //comments: PropTypes.string,
      isCommentRequired: PropTypes.number,
      isCommentAvailable: PropTypes.number,
      newComment: PropTypes.string
    })),
    downloading: PropTypes.bool,
    downloadURL: PropTypes.string,

    // Functions
    onToggleFilterDetailTable: PropTypes.func,

    // Redux Functions
    triggerUpdateData: PropTypes.func,
    downloadReport: PropTypes.func,
    downloadReportReset: PropTypes.func
  };

  static defaultProps = {
    showDashboardFilter: false,
    items: [],
    downloading: false,
    downloadURL: null,
    filterPeriod: {
      value: "Dec-2020",
      label: "DEC-2020"
    },
    filterCurrency: {
      value: "default",
      label: "DEFAULT"
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      editing: null,
      selectedComments: [],
      filters: {
        groupNumber: '',
        taxFileName: '',
        provider: '',
        recipient: '',
        owner: '',
        assignee: '',
        entity: '',
        transactionCurrency: '',
        account: '',
        effectiveDate: '',
        mmVar: '',
        currM3MAvg: ''
      },
      isHighlighted: false,
      isCurrMonExceed: false
    };
  }

  componentDidUpdate() {
    const { downloadURL } = this.props;
    if (downloadURL !== null) {
      console.log("downloadURL", downloadURL);
      window.open(downloadURL);
      const { downloadReportReset } = this.props;
      downloadReportReset();
    }
  }

  handleDownload = () => {
    const { downloadReport } = this.props;
    let filters = this.extractFilters();
    const period = this.props.filterPeriod.id ?? this.props.filterPeriod.value
    downloadReport(period, filters);
  }

  extractFilters = () => {
    const { filters, isHighlighted, isCurrMonExceed } = this.state;
    let result = {};
    for(const columnKey in filters) {
      if (filters[columnKey] != null) {
        result[columnKey] = filters[columnKey];
      }
    }

    return {
      ...result,
      ...(isHighlighted? {isHighlighted}: {}),
      ...(isCurrMonExceed? {isCurrMonExceed}: {})
    };
  }

  handleSubmit = (data) => {
    console.log('Form submission received!', data);
  };

  toggleFilter = () => {
    this.setState({ filters: {}, isHighlighted: false, isCurrMonExceed: false });
    this.props.onToggleFilterDetailTable();
  };


  /**
   * A function receiving row updates.
   * The first parameter is a new rows array with both the updated rows and the other untouched rows.
   * The second parameter is an object with an indexes array highlighting which rows have changed by their index,
   * and the column where the change happened.
   * @param rows
   * @param index
   */
  onGridRowsUpdated = ( rows, index ) => {
    // row refers to the updated row
    const row = rows[index.indexes[0]]
    if(row && row.newComment && row.newComment.length > 0) {
      this.props.triggerUpdateData(row);
    }
  }

  getRows() {
    const { items } = this.props;
    const { filters, isHighlighted, isCurrMonExceed } = this.state;
    const highlightedItems = isHighlighted ? items.filter(item => (item.isCommentRequired && item.isCommentRequired > 0 
      && (!item.isCommentAvailable || item.isCommentAvailable < 1))): items; 
    const exceedItems = highlightedItems && highlightedItems.length > 0 && isCurrMonExceed ? highlightedItems.filter(item => (item.prior00 
      && (( item.prior00.amountUSD && Math.abs(item.prior00.amountUSD) > 100000000) 
      || (!item.prior00.amountUSD && item.prior00.amount && Math.abs(item.prior00.amount) > 100000000)))): highlightedItems;
    return exceedItems.filter(item => {
      return (
          (filters.agreementNumber ? item.agreementNumber?.toLowerCase().includes(filters.agreementNumber.toLowerCase()) : true) &&
          (filters.taxFileName ? item.taxFileName?.toLowerCase().includes(filters.taxFileName.toLowerCase()) : true) &&
          (filters.groupNumber ? item.groupNumber?.toLowerCase().includes(filters.groupNumber.toLowerCase()) : true) &&
          (filters.provider ? item.provider?.toLowerCase().includes(filters.provider.toLowerCase()) : true) &&
          (filters.recipient ? item.recipient?.toLowerCase().includes(filters.recipient.toLowerCase()) : true) &&
          (filters.owner ? item.owner?.toLowerCase().includes(filters.owner.toLowerCase()) : true) &&
          (filters.assignee ? item.assignee?.toLowerCase().includes(filters.assignee.toLowerCase()) : true) &&
          (filters.entity ? item.entity?.toLowerCase().includes(filters.entity.toLowerCase()) : true) &&
          (filters.transactionCurrency ? item.transactionCurrency?.toLowerCase().includes(filters.transactionCurrency.toLowerCase()) : true) &&
          (filters.account ? item.account?.toLowerCase().includes(filters.account.toLowerCase()) : true)
      );
    });
  }

  // Pad item with leading zeroes up to size, default size is 2
  pad(item, size) {
    var s = String(item);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
  }

  renderHeaderCellWithInputFilter (props ) {
    return (<>
      {renderHeaderCellWithText(props)}
      {this?.props?.showDashboardFilter &&
      <input
          value={this.state.filters[props.column.key]}
          className={styles.inputFilter}
          onChange={(e) => this.setState({
                filters: {...this.state.filters, [props.column.key]: e.target.value}
              })}
          onClick={e => {e.stopPropagation()}}
      />}
    </>);
  };

  render() {
    const downloading = this.props.downloading;
    const filteredItems = this.getRows() ?? [];
    const monthColumnItems = [];
    const rowsCount = filteredItems.length;
    let amountSelector = "amount";

    // Creating column definitions
    if (this.props.filterCurrency.label !== "DEFAULT") {
      amountSelector = "amount" + this.props.filterCurrency.label;
    }
    for (let i = 12; i >= 0; i--) {
      const fallback = i === 0? 'CurrMon': `Prior${i}Mon`;
      let monthColumnItem = {
        name: rowsCount > 0? filteredItems[0]['prior' + this.pad(i)].month: fallback,
        key: `prior${this.pad(i)}.${amountSelector}`,
        width: 130,
        editable: false,
        dataType: 'monthlyAmount',
        renderCell: renderCell,
        renderHeaderCell: renderHeaderCellWithText,
        frozen: false
      };
      if(i === 0) {
        monthColumnItem = {
          ...monthColumnItem,
          run: (e) => {this.setState({isCurrMonExceed: e.detail.checked})},
          content: 'Only >$100M',
          checked: this.state.isCurrMonExceed,
          renderHeaderCell: renderHeaderCellWithCheckBox,
          headerCellClass: styles.filterColumn,
        }
      }
      monthColumnItems.push(monthColumnItem);
    }

    const columnItems = [
      ...this.propertyColumnItems,
      ...monthColumnItems,
      ...this.aggregateColumnItems,
      {
        name: 'Latest Comment',
        key: 'latestComment',
        width: 130,
        editable: false,
        dataType: 'latestComment',
        renderCell: renderCell,
        renderHeaderCell: renderHeaderCellWithText,
        frozen: false
      },
      {
        name: 'Comments Required',
        key: 'newComment',
        width: 155,
        run: (e) => {this.setState({isHighlighted: e.detail.checked})},
        content: 'Only highlighted',
        checked: this.state.isHighlighted,
        renderHeaderCell: renderHeaderCellWithCheckBox,
        headerCellClass: styles.filterColumn,
        dataType: 'newComment',
        renderCell: renderCell,
        editable: true,
        renderEditCell: textEditor,
        frozen: false
      },
    ];

    

    return (
        <Container header={
          <Header
              variant="h2"
              actions={
                <SpaceBetween
                    direction="horizontal"
                    size="xs"
                >
                  <Button
                      iconName="filter"
                      iconAlign="right"
                      onClick={this.toggleFilter}>
                    Filter Rows
                  </Button>
                  <Button
                      onClick={this.handleDownload}
                      disabled={downloading}
                      loading={downloading}
                      variant="primary">
                    {downloading ? 'Downloading...' : 'Download' }
                  </Button>
                </SpaceBetween>
              }
          >
            Detail View {`(${rowsCount})`}
          </Header>
        }>
          <div>
            <div>
              <FormProvider
                form="inline"
                onSubmit={this.handleSubmit}
                onSubmitSuccess={() => this.setState({ editing: null })}
                initialValues={this.state.editing}
                enableReinitialize>
                {(formProps) => {
                  return (
                    <form
                        onSubmit={formProps.handleSubmit}
                        name="detail-view-form">
                        <DataGrid
                            className={`${styles.filtercolumn} rdg-light`}
                            headerRowHeight={this.props.showDashboardFilter ? 70 : undefined}
                            columns={columnItems}
                            rows = {filteredItems}
                            onCellClick={(args, event) => {
                              if (args.column.key === 'latestComment') {
                                // find the element
                                var entry = args.row
                                // set state
                                if(entry && entry.commentList && entry.commentList.length > 0) {
                                  this.setState({
                                    selectedComments: entry.commentList.slice().reverse()
                                  });
                                }
                                event.preventGridDefault();
                                args.selectCell(true);
                              }
                            }}
                            onRowsChange={this.onGridRowsUpdated}
                        />
                    </form>
                  );
                }}
              </FormProvider>
            </div>
          </div>
          <DetailCommentsView
              comments={this.state.selectedComments}
              onDismiss={() => this.setState({ selectedComments: [] })}
          />
        </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { username } = state.auth;
  const { downloadURL, downloading } = state.landingPage;

  return {
    username,
    downloadURL,
    downloading
  };
};

const mapDispatchToProps = {
  downloadReport:      actions.downloadReport,
  downloadReportReset: actions.downloadReportReset,
  triggerUpdateData: actions.triggerUpdateData
};

const DetailDataViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DetailDataView);

export default withRouter(DetailDataViewContainer);