import React from 'react';
import AggregateDataView from "./AggregateDataView";
import DetailDataView from "./DetailDataView";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from '../../../utils/withRouter';
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";

/**
 * Renders the aggregated view and the detail view
 */
class DataViewBlock extends React.Component {

    static propTypes = {
      showDashboardFilter: PropTypes.bool,
      filterStatBy: PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
      }),
      filterTeam: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
      }),
      filterUser: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
      }),
      filterType: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
      }),
      filterGroup: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
      }),
      filterCurrency: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
      }),
      searchItems: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        source: PropTypes.string,
        groupNumber: PropTypes.string,
        taxFileName: PropTypes.string,
        agreementNumber: PropTypes.string,
        provider: PropTypes.string,
        recipient: PropTypes.string,
        ownerTeam: PropTypes.string,
        owner: PropTypes.string,
        assigneeTeam: PropTypes.string,
        assignee: PropTypes.string,
        entity: PropTypes.string,
        transactionCurrency: PropTypes.string,
        account: PropTypes.string,
        prior12: PropTypes.shape({
            month: PropTypes.string,
            amount: PropTypes.number,
            amountUSD: PropTypes.number,
            amountEUR: PropTypes.number,
        }),
        prior11: PropTypes.shape({
            month: PropTypes.string,
            amount: PropTypes.number,
            amountUSD: PropTypes.number,
            amountEUR: PropTypes.number,
        }),
        prior10: PropTypes.shape({
            month: PropTypes.string,
            amount: PropTypes.number,
            amountUSD: PropTypes.number,
            amountEUR: PropTypes.number,
        }),
        prior09: PropTypes.shape({
            month: PropTypes.string,
            amount: PropTypes.number,
            amountUSD: PropTypes.number,
            amountEUR: PropTypes.number,
        }),
        prior08: PropTypes.shape({
            month: PropTypes.string,
            amount: PropTypes.number,
            amountUSD: PropTypes.number,
            amountEUR: PropTypes.number,
        }),
        prior07: PropTypes.shape({
            month: PropTypes.string,
            amount: PropTypes.number,
            amountUSD: PropTypes.number,
            amountEUR: PropTypes.number,
        }),
        prior06: PropTypes.shape({
            month: PropTypes.string,
            amount: PropTypes.number,
            amountUSD: PropTypes.number,
            amountEUR: PropTypes.number,
        }),
        prior05: PropTypes.shape({
            month: PropTypes.string,
            amount: PropTypes.number,
            amountUSD: PropTypes.number,
            amountEUR: PropTypes.number,
        }),
        prior04: PropTypes.shape({
            month: PropTypes.string,
            amount: PropTypes.number,
            amountUSD: PropTypes.number,
            amountEUR: PropTypes.number,
        }),
        prior03: PropTypes.shape({
            month: PropTypes.string,
            amount: PropTypes.number,
            amountUSD: PropTypes.number,
            amountEUR: PropTypes.number,
        }),
        prior02: PropTypes.shape({
            month: PropTypes.string,
            amount: PropTypes.number,
            amountUSD: PropTypes.number,
            amountEUR: PropTypes.number,
        }),
        prior01: PropTypes.shape({
            month: PropTypes.string,
            amount: PropTypes.number,
            amountUSD: PropTypes.number,
            amountEUR: PropTypes.number,
        }),
        prior00: PropTypes.shape({
            month: PropTypes.string,
            amount: PropTypes.number,
            amountUSD: PropTypes.number,
            amountEUR: PropTypes.number,
        }),
        effectiveDate: PropTypes.string,
        mmVar: PropTypes.number,
        currM3MAvg: PropTypes.number,
        commentList: PropTypes.arrayOf(PropTypes.shape({
          user: PropTypes.string,
          content: PropTypes.string,
          ts: PropTypes.string,
        })),
        //comments: PropTypes.string,
        isCommentRequired: PropTypes.number,
        isCommentAvailable: PropTypes.number,
        newComment: PropTypes.string
      })),

      // Functions
      onToggleFilterDetailTable: PropTypes.func,
  
      // Redux Functions
      triggerUpdateData: PropTypes.func,
    };
  
    static defaultProps = {
        showDashboardFilter: false,
        filterStatBy: {
            value: "assignee",
            label: "Assignee"
        },
        filterTeam: {
            value: "all",
            label: "ALL"
        },
        filterUser: {
            value: "all",
            label: "ALL"
        },
        filterType: {
            value: "all",
            label: "ALL"
        },
        filterGroup: {
            value: "all",
            label: "ALL"
        },
        filterCurrency: {
            value: "default",
            label: "DEFAULT"
        },
        searchItems: [],
        onToggleFilterDetailTable: () => { },
        triggerUpdateData: () => { },
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    filterUserValues(items, statusByValue, filterUser, filterTeam) {
        let result = items;
        if(filterUser.value !== 'all') {
            if(statusByValue === 'Owner') {
                result = items.filter(item => (item.owner === filterUser.value));
            } else {
                result = items.filter(item => (item.assignee === filterUser.value));
            }
        }
        return (filterTeam.value === 'all')? result : result.filter(entry => (entry.assigneeTeam === filterTeam.value || entry.ownerTeam === filterTeam.value));
    }
      
    filterTypeValues(items, filterType) {
        if(filterType.value === 'all') {
            return items;
        }
        return items.filter(item => (item.type === filterType.label));
    }
    
    filterGroupValues(items, filterGroup) {
        if(filterGroup.value === 'all') {
            return items;
        }
        return items.filter(item => (item.groupNumber === filterGroup.label));
    }

    render() {
        const filterStatBy = this.props.filterStatBy;
        const filterUser = this.props.filterUser;
        const filterTeam = this.props.filterTeam;
        const filterType = this.props.filterType;
        const filterGroup = this.props.filterGroup;
        const filterCurrency = this.props.filterCurrency;
        let items = this.props.searchItems || [];

        // if props changes
        let statusByValue = (filterStatBy.value === 'owner')? 'Owner': 'Assignee';

        // filter values
        items = this.filterUserValues(items, statusByValue, filterUser, filterTeam);
        items = this.filterTypeValues(items, filterType);
        items = this.filterGroupValues(items, filterGroup);

        return (
            <SpaceBetween size={"m"}>
                <AggregateDataView
                    filterTrend={this.props.filterTrend}
                    filterStatBy={this.props.filterStatBy}
                    filterTeam={this.props.filterTeam}
                    filterUser={this.props.filterUser}
                    filterType={this.props.filterType}
                    filterGroup={this.props.filterGroup}
                    filterCurrency={this.props.filterCurrency}
                    statusByValue={ statusByValue }
                    items={ items }
                />
                { this.props.showDetailDataView &&
                    <DetailDataView 
                        filterPeriod={this.props.filterPeriod}
                        filterCurrency={this.props.filterCurrency}
                        showDashboardFilter={this.props.showDashboardFilter}
                        onToggleFilterDetailTable={this.props.onToggleFilterDetailTable}
                        items={ items }
                    />
                }
            </SpaceBetween>
        );
    }
}

const mapStateToProps = (state) => {
    const { username } = state.auth;

    return {
        username
    };
};

const mapDispatchToProps = {
};

const DataViewBlockContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DataViewBlock);

export default withRouter(DataViewBlockContainer);


        