// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.ICIntegrationBanner {
    width: 100%;
    background-color: black;
    text-align: center;
    margin-bottom: 1em;
}
 
div.ICIntegrationBanner h3 {
    padding: 0.6em;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/common/components/banner/ICBanner.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,uBAAuB;IACvB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,YAAY;AAChB","sourcesContent":["div.ICIntegrationBanner {\n    width: 100%;\n    background-color: black;\n    text-align: center;\n    margin-bottom: 1em;\n}\n \ndiv.ICIntegrationBanner h3 {\n    padding: 0.6em;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
