import React from 'react';
import styles from '../LandingPage.scss';
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import {Chart, Doughnut} from 'react-chartjs-2';
import ReactTable from 'react-table';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from '../../../utils/withRouter';
import {Grid} from "@amzn/awsui-components-react/polaris";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";

ChartJS.register(ArcElement, Tooltip, Legend);

const dataTemplate = {
  labels: [
    'Outstanding',
    'Completed'
  ],
  datasets: [
    {
      label: '# of Comments',
      data: [],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(75, 192, 192, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(75, 192, 192, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

class AggregateDataView extends React.Component {

  static propTypes = {
    statusByValue: PropTypes.string, 
    filterTrend: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
    }),
    filterStatBy: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
    }),
    filterTeam: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    }),
    filterUser: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    }),
    filterType: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    }),
    filterGroup: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    }),
    filterCurrency: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    }),
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      source: PropTypes.string,
      groupNumber: PropTypes.string,
      taxFileName: PropTypes.string,
      agreementNumber: PropTypes.string,
      provider: PropTypes.string,
      recipient: PropTypes.string,
      ownerTeam: PropTypes.string,
      owner: PropTypes.string,
      assigneeTeam: PropTypes.string,
      assignee: PropTypes.string,
      entity: PropTypes.string,
      transactionCurrency: PropTypes.string,
      account: PropTypes.string,
      prior12: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior11: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior10: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior09: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior08: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior07: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior06: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior05: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior04: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior03: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior02: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior01: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      prior00: PropTypes.shape({
        month: PropTypes.string,
        amount: PropTypes.number,
        amountUSD: PropTypes.number,
        amountEUR: PropTypes.number,
      }),
      effectiveDate: PropTypes.string,
      mmVar: PropTypes.number,
      currM3MAvg: PropTypes.number,
      commentList: PropTypes.arrayOf(PropTypes.shape({
        user: PropTypes.string,
        content: PropTypes.string,
        ts: PropTypes.string,
      })),
      isCommentRequired: PropTypes.number,
      isCommentAvailable: PropTypes.number,
      newComment: PropTypes.string
    }))
  };

  static defaultProps = {
    statusByValue: "Assignee",
    filterTrend: {
        value: "13m",
        label: "13-month"
    },
    filterStatBy: {
        value: "assignee",
        label: "Assignee"
    },
    filterTeam: {
        value: "all",
        label: "ALL"
    },
    filterUser: {
        value: "all",
        label: "ALL"
    },
    filterType: {
        value: "all",
        label: "ALL"
    },
    filterGroup: {
        value: "all",
        label: "ALL"
    },
    filterCurrency: {
        value: "default",
        label: "DEFAULT"
    },
    items: []
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  aggregatedColData(statusByValue, items) {
    let totalCommentsRequired = items.reduce((total, item) => total + item.comm_req, 0);
    let totalCommentsCompleted = items.reduce((total, item) => total + item.comm_comp, 0);
    let totalCommentsPending = totalCommentsRequired - totalCommentsCompleted;
    
    return [
      {
        Header: '#',
        id: 0,
        width: 40,
        Cell: row => <span>{row.index + 1}</span>,
        headerClassName: styles.leftAlignContent,
        filterable: false
      },
      {
        Header: 'TP Team',
        accessor: 'team',
        width: 140,
        headerClassName: styles.leftAlignContent
      },
      {
        Header: statusByValue,
        accessor: 'user',
        width: 140,
        headerClassName: styles.leftAlignContent
      },
      {
        Header: 'Comments Required',
        accessor: 'comm_req',
        width: 165,
        sortable: false,
        headerClassName: styles.leftAlignContent,
        Footer: String(totalCommentsRequired)
      },
      {
        Header: 'Comments Completed',
        accessor: 'comm_comp',
        width: 165,
        sortable: false,
        headerClassName: styles.leftAlignContent,
        Footer: String(totalCommentsCompleted)
      },
      {
        Header: 'Comments Outstanding',
        accessor: 'comm_out',
        width: 165,
        sortable: false,
        headerClassName: styles.leftAlignContent,
        Footer: String(totalCommentsPending)
      }
    ];
  }

  aggregatedData(statusByValue, items) {
    let count = {};
    const statusBy = statusByValue;
    if(statusByValue === 'Owner') {
      count = items.reduce( (tally, item) => {
        let obj = {};
        if(tally[item.owner]) {
          obj = tally[item.owner];
          obj.comm_req = obj.comm_req + (item.isCommentRequired || 0);
          obj.comm_comp = obj.comm_comp + ((item.isCommentRequired === 1)?(item.isCommentAvailable || 0): 0);
          obj.comm_out = obj.comm_req - obj.comm_comp;
          tally[item.owner] = obj;
        } else {
          obj.user = item.owner;
          obj.team = item.ownerTeam;
          obj.comm_req = (item.isCommentRequired || 0);
          obj.comm_comp = ((item.isCommentRequired === 1)?(item.isCommentAvailable || 0): 0);
          obj.comm_out = obj.comm_req - obj.comm_comp;
          tally[item.owner] = obj;
        }
        return tally;
      } , {});
    } else {
      count = items.reduce( (tally, item) => {
        let obj = {};
        if(tally[item.assignee]) {
          obj = tally[item.assignee];
          obj.comm_req = obj.comm_req + (item.isCommentRequired || 0);
          obj.comm_comp = obj.comm_comp + ((item.isCommentRequired === 1)?(item.isCommentAvailable || 0): 0);
          obj.comm_out = obj.comm_req - obj.comm_comp;
          tally[item.assignee] = obj;
        } else {
          obj.user = item.assignee;
          obj.team = item.assigneeTeam;
          obj.comm_req = (item.isCommentRequired || 0);
          obj.comm_comp = ((item.isCommentRequired === 1)?(item.isCommentAvailable || 0): 0);
          obj.comm_out = obj.comm_req - obj.comm_comp;
          tally[item.assignee] = obj;
        }
        return tally;
      } , {});
    }
    return Object.values(count);
  }

  aggregatedChartData(dataTemplate, items, statusByValue) {
    let totalCommentsRequired = items.reduce((total, item) => total + item.comm_req, 0);
    let totalCommentsCompleted = items.reduce((total, item) => total + item.comm_comp, 0);
    let totalCommentsPending = totalCommentsRequired - totalCommentsCompleted;

    return {
      labels: ['Outstanding', 'Completed'],
      datasets: [
        {
          label: '# of Comments',
          data: [totalCommentsPending.valueOf(), totalCommentsCompleted.valueOf()],
          backgroundColor: [
            'rgba(255, 0, 0, 0.2)',
            'rgba(0, 255, 0, 0.2)',
          ],
          borderColor: [
            'rgba(255, 0, 0, 1)',
            'rgba(0, 255, 0, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
  }

  render() {
    const { statusByValue } = this.props;
    let items = this.props.items;

    // compute agg data
    let aggItems = this.aggregatedData(statusByValue, items);

    // compute col array
    let columnItems = this.aggregatedColData(statusByValue, aggItems);

    // compute donut data
    let data = this.aggregatedChartData(dataTemplate, aggItems, statusByValue);

    const textCenter = {
      id: 'textCenter',
      beforeDatasetDraw(chart, args, options) {
        const { ctx, data } = chart;
        ctx.save();
        ctx.font = 'bold 12px Arial';
        ctx.fillStyle = '#000';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(
            `Total: ${data.datasets[0].data[0] + data.datasets[0].data[1]}`,
            chart.getDatasetMeta(0).data[0].x,
            chart.getDatasetMeta(0).data[0].y)
      }
    }

    return (
        <Grid gridDefinition={[{ colspan: 4 }, { colspan: 8 }]}>
          <Container
            fitHeight={true}
            header={
            <Header
                variant="h2"
            >
             {statusByValue} Comments Outstanding
            </Header>
            }>
            <Doughnut
                data={data}
                plugins={[textCenter]}
            />
          </Container>
          <Container
              fitHeight={true}
              header={
                <Header
                    variant="h2"
                >
                  {statusByValue} Comment Status
                </Header>
              }>
              <ReactTable
                  data={aggItems}
                  showPageSizeOptions={false}
                  pageSize={10}
                  minRows={0}
                  columns={columnItems}
                  sortable
                  loadingText="Searching..."
                  resizableColumns
                  showPagination={aggItems != null && aggItems.length > 10}
                  noMatch="noMatchContent"
              />
          </Container>
        </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  const { username } = state.auth;

  return {
    username
  };
};

const mapDispatchToProps = {
};

const AggregateDataViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AggregateDataView);

export default withRouter(AggregateDataViewContainer);