// Constants for Redux Action Names
 
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
// Trending Rpt: Constants for Redux Action Names

export const LANDING_PAGE_SEARCH_START = 'LANDING_PAGE_SEARCH_START';
export const LANDING_PAGE_SEARCH_SUCCESS = 'LANDING_PAGE_SEARCH_SUCCESS';
export const LANDING_PAGE_SEARCH_FAILURE = 'LANDING_PAGE_SEARCH_FAILURE';
export const LANDING_PAGE_ADD_COMMENT = 'LANDING_PAGE_ADD_COMMENT';

export const EXPORT_TABLE_START = 'EXPORT_TABLE_REQUEST';
export const EXPORT_TABLE_SUCCESS = 'EXPORT_TABLE_SUCCESS';
export const EXPORT_TABLE_FAILURE = 'EXPORT_TABLE_FAILURE';
export const EXPORT_TABLE_RESET   = 'EXPORT_TABLE_RESET';

export const LANDING_PAGE_POST_COMMENT_START = 'LANDING_PAGE_POST_COMMENT_START';
export const LANDING_PAGE_POST_COMMENT_SUCCESS = 'LANDING_PAGE_POST_COMMENT_SUCCESS';
export const LANDING_PAGE_POST_COMMENT_FAILURE = 'LANDING_PAGE_POST_COMMENT_FAILURE';

export const RESET_FETCH_BUTTON_VISIBILITY = 'RESET_FETCH_BUTTON_VISIBILITY';