// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pages-landingPage-components-DetailDataView__filterColumn--TaNog{line-height:35px;padding:0}.pages-landingPage-components-DetailDataView__filterColumn--TaNog>div{padding-block:0;padding-inline:8px}.pages-landingPage-components-DetailDataView__filterColumn--TaNog>div:first-child{border-block-end:1px solid var(--rdg-border-color)}.pages-landingPage-components-DetailDataView__inputFilter--IoJ6Q{inline-size:100%;padding:4px;margin:4px auto;width:95%;font-size:14px;display:block}
`, "",{"version":3,"sources":["webpack://./src/pages/landingPage/components/DetailDataView.scss"],"names":[],"mappings":"AAAA,kEACE,gBAAiB,CACjB,SAAU,CAFZ,sEAKI,eAAgB,CAChB,kBAAmB,CANvB,kFASM,kDAAmD,CACpD,iEAKH,gBAAiB,CACjB,WAAY,CACZ,eAAgB,CAChB,SAAU,CACV,cAAe,CACf,aAAc","sourcesContent":[".filterColumn {\n  line-height: 35px;\n  padding: 0;\n\n  > div {\n    padding-block: 0;\n    padding-inline: 8px;\n\n    &:first-child {\n      border-block-end: 1px solid var(--rdg-border-color);\n    }\n  }\n}\n\n.inputFilter {\n  inline-size: 100%;\n  padding: 4px;\n  margin: 4px auto;\n  width: 95%;\n  font-size: 14px;\n  display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterColumn": `pages-landingPage-components-DetailDataView__filterColumn--TaNog`,
	"inputFilter": `pages-landingPage-components-DetailDataView__inputFilter--IoJ6Q`
};
export default ___CSS_LOADER_EXPORT___;
