import React from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Header from "@amzn/awsui-components-react/polaris/header";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import { useCollection } from '@amzn/awsui-collection-hooks';

const TIMESTAMP_COL_ID = "ts";
const USER_COL_ID = "user";
const CONTENT_COL_ID = "content";

const TableNoMatchState = ({ onClearFilter }) => (
    <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
            <div>
                <b>No matches</b>
                <Box variant="p" color="inherit">
                    We can't find a match.
                </Box>
            </div>
            <Button onClick={onClearFilter}>Clear filter</Button>
        </SpaceBetween>
    </Box>
);

const TableEmptyState = ({ resourceName }) => (
    <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
            <div>
                <b>No {resourceName.toLowerCase()}s</b>
                <Box variant="p" color="inherit">
                    No {resourceName.toLowerCase()}s associated with this resource.
                </Box>
            </div>
            <Button>Create {resourceName.toLowerCase()}</Button>
        </SpaceBetween>
    </Box>
);

function DetailCommentsView({ comments, onDismiss }) {
    const [preferences, setPreferences] = React.useState({
        pageSize: 6,
        contentDisplay: [
            { id: TIMESTAMP_COL_ID, visible: true },
            { id: USER_COL_ID, visible: true },
            { id: CONTENT_COL_ID, visible: true }
        ]
    });
    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        comments,
        {
            filtering: {
                empty: <TableEmptyState resourceName="Distribution" />,
                noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />,
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: { defaultState: { sortingColumn: TIMESTAMP_COL_ID } },
            selection: {},
        }
    );
    return (
        <Modal
            onDismiss={onDismiss}
            visible={comments.length > 0}
            size="medium"
            header="Detail View Comments"
        >
            <Table
                {...collectionProps}
                columnDefinitions={[
                    {
                        id: TIMESTAMP_COL_ID,
                        header: "Timestamp",
                        label: sortState => {
                            const columnIsSorted = sortState.sortingColumn === TIMESTAMP_COL_ID;
                            const ascending = !sortState.sortingDescending;
                            return `Timestamp, ${
                                columnIsSorted
                                    ? `sorted ${ascending ? 'ascending' : 'descending'}`
                                    : 'not sorted'
                            }.`;
                        },
                        cell: item => {
                            return item.ts || "-";
                        },
                        minWidth: '30px'
                    },
                    {
                        id: USER_COL_ID,
                        header: "User",
                        cell: item => item.user || "-",
                        minWidth: '30px'
                    },
                    {
                        id: CONTENT_COL_ID,
                        header: "Comment",
                        cell: item => item.content || "-",
                        minWidth: '100px'
                    }
                ]}
                columnDisplay={[
                    { id: TIMESTAMP_COL_ID, visible: true },
                    { id: USER_COL_ID, visible: true },
                    { id: CONTENT_COL_ID, visible: true }
                ]}
                items={items}
                loadingText="Loading resources"
                trackBy="name"
                empty={
                    <Box
                        margin={{ vertical: "xs" }}
                        textAlign="center"
                        color="inherit"
                    >
                        <SpaceBetween size="m">
                        </SpaceBetween>
                    </Box>
                }
                filter={
                    <TextFilter
                        {...filterProps}
                        filteringPlaceholder="Find resources"
                        countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
                    />
                }
                pagination={<Pagination {...paginationProps} />}
                header={
                    <Header
                        counter={
                            "(" + comments.length + ")"
                        }
                    >
                        Comments History
                    </Header>
                }
                preferences={
                    <CollectionPreferences
                        title="Preferences"
                        confirmLabel="Confirm"
                        cancelLabel="Cancel"
                        onConfirm={({ detail }) => setPreferences(detail)}
                        preferences={preferences}
                        pageSizePreference={{
                            title: "Page size",
                            options: [
                                { value: 2, label: "2 items" },
                                { value: 4, label: "4 items" },
                                { value: 6, label: "6 items" }
                            ]
                        }}
                    />
                }
            />
        </Modal>
    );
}

export default DetailCommentsView;