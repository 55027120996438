import React from 'react';
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import Select from "@amzn/awsui-components-react/polaris/select";
import Box from "@amzn/awsui-components-react/polaris/box";
import Form from "@amzn/awsui-components-react/polaris/form";
import Button from "@amzn/awsui-components-react/polaris/button";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from '../../../utils/withRouter';
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";

class SearchQueryBlock extends React.Component {

  static propTypes = {
    username: PropTypes.string,
    filterPeriod: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    }),
    filterTrend: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    }),
    filterStatBy: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    }),
    filterTeam: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    }),
    filterUser: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    }),
    filterType: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    }),
    filterGroup: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    }),
    filterCurrency: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    }),
    showDashboardFilter: PropTypes.bool,
    showDetailDataView: PropTypes.bool,
    downloading: PropTypes.bool,
    enableDataFetchButton: PropTypes.bool,
    isfetchingData: PropTypes.bool,

    // Functions
    onPeriodSelectionChange: PropTypes.func,
    onTrendSelectionChange: PropTypes.func,
    onFilterStatByChange: PropTypes.func,
    onFilterTeamChange: PropTypes.func,
    onFilterUserChange: PropTypes.func,
    onFilterTypeChange: PropTypes.func,
    onFilterGroupChange: PropTypes.func,
    onFilterCurrencyChange: PropTypes.func,
    toggleShowDetailDataView: PropTypes.func,
    onRestoreDefaultsButtonClick: PropTypes.func,
    onNewFetchReport: PropTypes.func,
  };

  static defaultProps = {
    username: null,
    searchItems: [],
    filterPeriod: {
      value: 'Dec-2020',
      label: 'DEC-2020'
    },
    filterTrend: {
      value: "13m",
      label: "13-month"
    },
    filterStatBy: {
      value: "assignee",
      label: "Assignee"
    },
    filterTeam: {
      value: "all",
      label: "ALL"
    },
    filterUser: {
      value: "all",
      label: "ALL"
    },
    filterType: {
      value: "all",
      label: "ALL"
    },
    filterGroup: {
      value: "all",
      label: "ALL"
    },
    filterCurrency: {
      value: "default",
      label: "DEFAULT"
    },
    showDashboardFilter: false,
    showDetailDataView: false,
    downloading: false,
    enableDataFetchButton: true,
    isfetchingData: false,
    onPeriodSelectionChange: () => { },
    onTrendSelectionChange: () => { },
    onFilterStatByChange: () => { },
    onFilterTeamChange: () => { },
    onFilterUserChange: () => { },
    onFilterTypeChange: () => { },
    onFilterGroupChange: () => { },
    onFilterCurrencyChange: () => { },
    toggleShowDetailDataView: () => { },
    onRestoreDefaultsButtonClick: () => { },
    onNewFetchReport: () => { }
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.handleSelectPeriodChange = this.handleSelectPeriodChange.bind(this);
    this.handleSelectTrendChange = this.handleSelectTrendChange.bind(this);
    this.handleSelectStatByChange = this.handleSelectStatByChange.bind(this);
    this.handleSelectUserChange = this.handleSelectUserChange.bind(this);
    this.handleSelectTeamChange = this.handleSelectTeamChange.bind(this);
    this.handleSelectTypeChange = this.handleSelectTypeChange.bind(this);
    this.handleSelectGroupChange = this.handleSelectGroupChange.bind(this);
    this.handleSelectCurrencyChange = this.handleSelectCurrencyChange.bind(this);
    this.handleRestoreDefaults = this.handleRestoreDefaults.bind(this);
  }

  componentDidMount() {
  }

  handleNewFetchReport = () => {
    const parameters = {
      "period": this.props.filterPeriod.value || "",
      "duration": this.props.filterTrend.value || ""
    }
    this.props.onNewFetchReport(parameters);
  }

  handleRestoreDefaults() {
    this.props.onRestoreDefaultsButtonClick();
  }

  handleSelectPeriodChange(selectedAction) {
    this.props.onPeriodSelectionChange(selectedAction);
  }

  handleSelectTrendChange(selectedAction) {
    this.props.onTrendSelectionChange(selectedAction);
  }

  handleSelectStatByChange(selectedAction) {
    this.props.onFilterStatByChange(selectedAction);
  }

  handleSelectUserChange(selectedAction) {
    this.props.onFilterUserChange(selectedAction);
  }

  handleSelectTeamChange(selectedAction) {
    this.props.onFilterTeamChange(selectedAction);
  }

  handleSelectTypeChange(selectedAction) {
    this.props.onFilterTypeChange(selectedAction);
  }

  handleSelectGroupChange(selectedAction) {
    this.props.onFilterGroupChange(selectedAction);
  }

  handleSelectCurrencyChange(selectedAction) {
    this.props.onFilterCurrencyChange(selectedAction);
  }

  generatePeriodOptionList() {
    let options = [];
    let prevPeriodDate = new Date();
    let counter = 7;
    let prevPeriodLabel = "";
    do {
      prevPeriodDate.setMonth(prevPeriodDate.getMonth() - 1);
      const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(prevPeriodDate);
      const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(prevPeriodDate);
      const prevPeriod = month + "-" + year;
      prevPeriodLabel = prevPeriod.toUpperCase();
      if(prevPeriodLabel !== "NOV-2020") {
        options.push({
          "label": prevPeriodLabel,
          "value": prevPeriod
        });
      }
      counter--;
    } while(counter > 0 && prevPeriodLabel && prevPeriodLabel !== "NOV-2020");

    return options;
  }

  render() {
    const optionsPeriod = this.generatePeriodOptionList();
    const optionsTrend = [
      {
        "label": "13-month",
        "value": "13m"
      },
    ];
    const optionsStatBy = [
      {
        "value": "assignee",
        "label": "Assignee"
      },
      {
        "value": "owner",
        "label": "Owner"
      }
    ];
    const optionsType = [
      {
        "label": "ALL",
        "value": "all"
      },
      {
        "label": "Automated",
        "value": "automated"
      },
      {
        "label": "MJE",
        "value": "mje"
      }
    ];
    const optionsCurrency = [
      {
        "label": "DEFAULT",
        "value": "default"
      },
      {
        "label": "USD",
        "value": "usd"
      },
      {
        "label": "EUR",
        "value": "eur"
      }
    ];

    const {
      userItems, 
      teamItems, 
      groupNumberItems, 
      onNewFetchReport,
      showDetailDataView,
      downloading,
      enableDataFetchButton,
      disableDataFetchButton,
      isfetchingData
    } = this.props;

    console.log("DOWNLOADING && ISFETCHINGDATA && ENABLEDATAFETCHBUTTON && DISABLEDATAFETCHBUTTON");
    console.log(downloading + "&&" + isfetchingData + " && " + enableDataFetchButton + " && " + disableDataFetchButton);

    return (
        <Box>
                      <Form
                          actions={
                              <div/>
                          }
                      >
                          <Container
                              header={
                                <Header variant="h2">
                                  Intercompany Transfer Pricing Report
                                </Header>
                              }
                              footer={
                                <Header
                                    actions={
                                      <SpaceBetween
                                          direction="horizontal"
                                          size="xs"
                                      >
                                        <Button
                                            disabled={downloading}
                                            variant="link"
                                            onClick={this.handleRestoreDefaults}
                                            children="Restore Defaults"
                                        />
                                        <Button
                                            disabled={downloading}
                                            variant="normal"
                                            onClick={this.props.toggleShowDetailDataView}
                                            children={showDetailDataView ? 'Hide Detail View': 'Show Detail View'}
                                        />
                                        <Button
                                            disabled={downloading || !enableDataFetchButton || disableDataFetchButton}
                                            variant="primary"
                                            children="Fetch Report"
                                            loading={isfetchingData}
                                            onClick={this.handleNewFetchReport}
                                        />
                                      </SpaceBetween>
                                    }
                                ></Header>
                              }
                          >
                              <ColumnLayout columns={4}>
                                      <FormField label="Period" errorText="">
                                          <Select 
                                              options={optionsPeriod} 
                                              selectedOption={this.props.filterPeriod} 
                                              selectedLabel="Selected"
                                              disabled={isfetchingData}
                                              onChange={e => this.handleSelectPeriodChange(e.detail.selectedOption)}>
                                          </Select>
                                      </FormField>

                                      <FormField label="Trend" errorText="">
                                          <Select 
                                              options={optionsTrend} 
                                              selectedOption={this.props.filterTrend} 
                                              selectedLabel="Selected"
                                              disabled={isfetchingData}
                                              onChange={e => this.handleSelectTrendChange(e.detail.selectedOption)}>
                                          </Select>
                                      </FormField>

                                      <FormField label="Status By" errorText="">
                                          <Select 
                                              options={optionsStatBy} 
                                              selectedOption={this.props.filterStatBy} 
                                              selectedLabel="Selected"
                                              onChange={e => this.handleSelectStatByChange(e.detail.selectedOption)}>
                                          </Select>
                                      </FormField>

                                      <FormField label="Team" errorText="">
                                          <Select 
                                              options={teamItems} 
                                              selectedOption={this.props.filterTeam} 
                                              selectedLabel="Selected"
                                              onChange={e => this.handleSelectTeamChange(e.detail.selectedOption)}>
                                          </Select>
                                      </FormField>

                                      <FormField label="User" errorText="">
                                          <Select 
                                              options={userItems} 
                                              selectedOption={this.props.filterUser} 
                                              filteringType="auto" 
                                              filteringLabel="Filter options" 
                                              selectedLabel="Selected"
                                              onChange={e => this.handleSelectUserChange(e.detail.selectedOption)}>
                                          </Select>
                                      </FormField>

                                      <FormField label="Group" errorText="">
                                          <Select 
                                              options={groupNumberItems} 
                                              selectedOption={this.props.filterGroup} 
                                              selectedLabel="Selected"
                                              onChange={e => this.handleSelectGroupChange(e.detail.selectedOption)}>
                                          </Select>
                                      </FormField>

                                      <FormField label="Currency" errorText="">
                                          <Select 
                                              options={optionsCurrency} 
                                              selectedOption={this.props.filterCurrency} 
                                              selectedLabel="Selected"
                                              onChange={e => this.handleSelectCurrencyChange(e.detail.selectedOption)}>
                                          </Select>
                                      </FormField>
                              </ColumnLayout>
                          </Container>
                      </Form>
        </Box>
    );
  }
}

const mapStateToProps = (state) => {
  const { username } = state.auth;
  const { userItems, 
    teamItems, 
    groupNumberItems, 
    disableDataFetchButton,
    downloading,
    isfetchingData
  } = state.landingPage;
  
  return {
    username,
    userItems,
    teamItems,
    groupNumberItems,
    downloading,
    disableDataFetchButton,
    isfetchingData,
  };
};

const mapDispatchToProps = {
  
};

const SearchQueryBlockContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchQueryBlock);

export default withRouter(SearchQueryBlockContainer);