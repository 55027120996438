import React from 'react';
import styles from './LandingPage.scss';
import ICBanner from '../../common/components/banner/ICBanner';
import ICTrendingDashboard from './components/ICTrendingDashboard';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { withRouter } from '../../utils/withRouter';
 
export class LandingPage extends React.Component {

    static propTypes = {
        username: PropTypes.string,

        // Redux Functions
        myDashboardSearch: PropTypes.func,
    };

    static defaultProps = {
        username: null,
        myDashboardSearch: () => { },
    };
 
    constructor(props) {
        super(props);
         
        this.state = {
        }

        this.handleFetchReport = this.handleFetchReport.bind(this);
    }
 
    componentDidMount() { 
        this.handleFetchReport(null);
    }

    initPayload() {
        let latestPeriodDate = new Date();
        latestPeriodDate.setMonth(latestPeriodDate.getMonth() - 1);

        const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(latestPeriodDate);
        const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(latestPeriodDate);

        const latestPeriod = month + "-" + year;
        console.log("latestPeriod", latestPeriod);

        return {
            period: latestPeriod,
            duration: "13m"
        };
    }

    handleFetchReport(parameters) {
        const { myDashboardSearch } = this.props;
        if(!parameters || !parameters.period || !parameters.duration) {
            parameters = this.initPayload();
        }
        myDashboardSearch(parameters)
    }

    render() {
        const { username } = this.props;
        const bannerText = `Welcome ${username} to the Intercompany Transfer Pricing Trending Report`;

        var { isLoading } = this.props;
        //if (isLoading) return <span aria-label="Loading table content."><Spinner size="large"/><div>Loading...</div></span>
        if (this.state.requestFailed) return <p>Failed!</p>
        return(
            <div className={styles.wrapper}>
                <ICBanner bannerText={bannerText}></ICBanner>

                <ICTrendingDashboard
                    onNewFetchReport={this.handleFetchReport}
                />
            </div>
        );
    }
 
}

const mapStateToProps = (state) => {
    const { isLoading } = state.landingPage;
    const { username } = state.auth;
    return {
      username,
      isLoading
    };
  };
  
const mapDispatchToProps = {
    myDashboardSearch: actions.myDashboardSearch,
};

const LandingPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(LandingPage);
 
export default withRouter(LandingPageContainer);