import React from 'react';
import styles from '../LandingPage.scss';
import "@amzn/awsui-global-styles/polaris.css";
import 'react-table/react-table.css'
import * as actions from '../../../actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SearchQueryBlock from "./SearchQueryBlock";
import DataViewBlock from './DataViewBlock';
import { withRouter } from '../../../utils/withRouter';
import Box from "@amzn/awsui-components-react/polaris/box";


class ICTrendingDashboard extends React.Component {

  static propTypes = {
    username: PropTypes.string,
    searchItems: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      source: PropTypes.string,
      groupNumber: PropTypes.string,
      taxFileName: PropTypes.string,
      agreementNumber: PropTypes.string,
      provider: PropTypes.string,
      recipient: PropTypes.string,
      ownerTeam: PropTypes.string,
      owner: PropTypes.string,
      assigneeTeam: PropTypes.string,
      assignee: PropTypes.string,
      entity: PropTypes.string,
      transactionCurrency: PropTypes.string,
      account: PropTypes.string,
      jun19: PropTypes.number,
      jul19: PropTypes.number,
      aug19: PropTypes.number,
      effectiveDate: PropTypes.string,
      mmVar: PropTypes.number,
      currM3MAvg: PropTypes.number,
      commentList: PropTypes.arrayOf(PropTypes.shape({
        user: PropTypes.string,
        content: PropTypes.string,
        ts: PropTypes.string,
      })),
      isCommentRequired: PropTypes.number,
      isCommentAvailable: PropTypes.number,
      newComment: PropTypes.string
    })),

    // Functions
    onNewFetchReport: PropTypes.func, 
    resetFetchButtonVisibilty: PropTypes.func,
  };

  static defaultProps = {
    username: null,
    searchItems: [],
    onNewFetchReport: () => { },
    resetFetchButtonVisibilty: () => { }
  };
 
  constructor(props) {
    super(props);
    this.state = {
    };
    this.handleRestoreDefaults = this.handleRestoreDefaults.bind(this);
    this.handleSelectPeriodChange = this.handleSelectPeriodChange.bind(this);
    this.handleSelectTrendChange = this.handleSelectTrendChange.bind(this);
    this.handleSelectStatByChange = this.handleSelectStatByChange.bind(this);
    this.handleSelectUserChange = this.handleSelectUserChange.bind(this);
    this.handleSelectTeamChange = this.handleSelectTeamChange.bind(this);
    this.handleSelectTypeChange = this.handleSelectTypeChange.bind(this);
    this.handleSelectGroupChange = this.handleSelectGroupChange.bind(this);
    this.handleSelectCurrencyChange = this.handleSelectCurrencyChange.bind(this);
    this.toggleFilterDetailTable = this.toggleFilterDetailTable.bind(this);
    this.handleToggleShowDetailDataView = this.handleToggleShowDetailDataView.bind(this);
  }

  componentDidMount() {
    let latestPeriodDate = new Date();
    latestPeriodDate.setMonth(latestPeriodDate.getMonth() - 1);

    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(latestPeriodDate);
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(latestPeriodDate);

    const latestPeriod = month + "-" + year;
    const latestPeriodLabel = latestPeriod.toUpperCase();

    this.setState({
      selectedPeriod: {value: latestPeriod, label: latestPeriodLabel},
      filterStatBy: {value: 'assignee', label: 'Assignee'},
      filterTeam: {value: 'all', label: 'ALL'},
      filterUser: {value: 'all', label: 'ALL'},
      filterType: {value: 'all', label: 'ALL'},
      filterGroup: {value: 'all', label: 'ALL'},
      filterCurrency: {value: 'default', label: 'DEFAULT'},
      showDashboardFilter: false,
      showDetailDataView: true,
      enableDataFetchButton: true
    });
  }

  setDefaultFilterValues() {
    this.setState({
      filterStatBy: {value: 'assignee', label: 'Assignee'},
      filterTeam: {value: 'all', label: 'ALL'},
      filterUser: {value: 'all', label: 'ALL'},
      filterType: {value: 'all', label: 'ALL'},
      filterGroup: {value: 'all', label: 'ALL'},
      filterCurrency: {value: 'default', label: 'DEFAULT'},
      showDashboardFilter: false,
      showDetailDataView: true
    });
  }

  handleRestoreDefaults() {
    this.setDefaultFilterValues();
  }

  handleSelectPeriodChange(selectedPeriodValue) {
    this.setState({
      selectedPeriod: selectedPeriodValue,
      enableDataFetchButton: true
    });
    this.setDefaultFilterValues();
    this.props.resetFetchButtonVisibilty();
  }

  handleSelectTrendChange(selectedTrendValue) {
    this.setState({
      selectedTrend: selectedTrendValue,
      enableDataFetchButton: true
    });
    this.setDefaultFilterValues();
    this.props.resetFetchButtonVisibilty();
  }

  handleSelectStatByChange(selectedStatByValue) {
    this.setState({
      filterStatBy: selectedStatByValue,
      showDashboardFilter: false
    })
  }

  handleSelectUserChange(selectedUserValue) {
    this.setState({
      filterUser: selectedUserValue,
      showDashboardFilter: false
    })
  }

  handleSelectTeamChange(selectedTeamValue) {
    this.setState({
      filterTeam: selectedTeamValue,
      showDashboardFilter: false
    })
  }

  handleSelectTypeChange(selectedTypeValue) {
    this.setState({
      filterType: selectedTypeValue,
      showDashboardFilter: false
    })
  }

  handleSelectGroupChange(selectedGroupValue) {
    this.setState({
      filterGroup: selectedGroupValue,
      showDashboardFilter: false
    })
  }

  handleSelectCurrencyChange(selectedCurrencyValue) {
    this.setState({
      filterCurrency: selectedCurrencyValue
    })
  }

  toggleFilterDetailTable() {
    const { showDashboardFilter } = this.state;
    this.setState(() => ({ showDashboardFilter: !showDashboardFilter }));
  }
  
  handleToggleShowDetailDataView() {
    const { showDetailDataView } = this.state;
    this.setState(() => ({ showDetailDataView: !showDetailDataView }));
  }

  render() {
    const { username, searchItems } = this.props;
    return (
      <Box>
        <SearchQueryBlock
          filterPeriod={this.state.selectedPeriod}
          onPeriodSelectionChange={this.handleSelectPeriodChange}
          filterTrend={this.state.selectedTrend}
          onTrendSelectionChange={this.handleSelectTrendChange}
          filterStatBy={this.state.filterStatBy}
          onFilterStatByChange={this.handleSelectStatByChange}
          filterTeam={this.state.filterTeam}
          onFilterTeamChange={this.handleSelectTeamChange}
          filterUser={this.state.filterUser}
          onFilterUserChange={this.handleSelectUserChange}
          filterType={this.state.filterType}
          onFilterTypeChange={this.handleSelectTypeChange}
          filterGroup={this.state.filterGroup}
          onFilterGroupChange={this.handleSelectGroupChange}
          filterCurrency={this.state.filterCurrency}
          onFilterCurrencyChange={this.handleSelectCurrencyChange}
          showDashboardFilter={this.state.showDashboardFilter}
          showDetailDataView={this.state.showDetailDataView}
          toggleShowDetailDataView={this.handleToggleShowDetailDataView}
          onRestoreDefaultsButtonClick={this.handleRestoreDefaults}
          enableDataFetchButton={this.state.enableDataFetchButton}
          onNewFetchReport={this.props.onNewFetchReport}
        />

        <DataViewBlock
          filterPeriod={this.state.selectedPeriod}
          filterTrend={this.state.selectedTrend}
          filterStatBy={this.state.filterStatBy}
          filterTeam={this.state.filterTeam}
          filterUser={this.state.filterUser}
          filterType={this.state.filterType}
          filterGroup={this.state.filterGroup}
          filterCurrency={this.state.filterCurrency}
          showDetailDataView={this.state.showDetailDataView}
          showDashboardFilter={this.state.showDashboardFilter}
          onToggleFilterDetailTable={this.toggleFilterDetailTable}
          searchItems={this.props.searchItems}
        />
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  const { searchItems } = state.landingPage;
  const { username } = state.auth;
  
  return {
    username,
    searchItems
  };
};

const mapDispatchToProps = {
  myDashboardSearch: actions.myDashboardSearch,
  resetFetchButtonVisibilty: actions.resetFetchButtonVisibilty
};

const ICTrendingDashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ICTrendingDashboard);

export default withRouter(ICTrendingDashboardContainer);