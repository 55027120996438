import { CognitoAuth } from 'amazon-cognito-auth-js';

const authConfig = {
    AppWebDomain: process.env.USER_POOL_SUB_DOMAIN,
    ClientId: process.env.USER_POOL_APP_CLIENT_ID,
    UserPoolId: process.env.USER_POOL_APP_CLIENT_ID,
    RedirectUriSignIn: process.env.REDIRECT_URI_SIGNIN,
    RedirectUriSignOut: process.env.REDIRECT_URI_SIGNOUT,
    TokenScopesArray: [
        'openid',
        'email',
    ],
};

// This code removes the "?code=..." from the URL. It is because the grant code is not reusable.
//  Sometimes the SDK will report weird message because of using old grant code.
function removeQueryFromLocation() {
    // Replace the href because the Cognito passes the OAuth2 grant code in the query string
    // And the grant code is not reusable
    if (window.history.length > 0) {
        const newHref = window.location.href.split('?')[0];
        window.history.replaceState(undefined, 'ICE', newHref);
    }
}
 
export function getUsernameFromCognitoSession(session) {
    try {
        const { identities } = session.getIdToken().decodePayload();
        if (identities.length < 1) {
            return null; // No valid identities; return null.
        }
        return identities[0].userId;
    } catch (e) {
        return null; // Received Invalid object; return null.
    }
}
 
export function getJwtTokenFromCognitoSession(session) {
    return session.getIdToken().getJwtToken();
}
 
/**
 * Ensures the user is authenticated.
 * If authenticated, return auth object. Otherwise force authentication.
 * Auth object will be used to call API gateway
 */
export function ensureAuthenticated() {
    return new Promise((resolve, reject) => {
        const auth = new CognitoAuth(authConfig);
        auth.useCodeGrantFlow();
        // Register callback functions
        auth.userhandler = {
            onFailure: (err) => {
                console.log('Cognito onFailure', err);
                removeQueryFromLocation();
        
                if (err === '{"error":"invalid_grant"}') {
                    // Cached localStorage contained invalid refresh token;
                    // Clear cached Cognito data and try again.
                    localStorage.clear();
                    ensureAuthenticated()
                        .then(result => resolve(result))
                        .catch(err2 => reject(err2));
                } else {
                    reject(err);
                }
            },
            onSuccess: (result) => {
                console.log('Cognito Session:', result);
                removeQueryFromLocation();
                resolve(auth);
            },
        };
    
        const { href } = window.location;
        let session = auth.getSignInUserSession();
        // console.log('Cognito Session:', session);
        if (session.isValid()) {
            // Already logged in; return auth session.
            resolve(auth);
        } else if (href.indexOf('?') > 0) {
            // Handle Cognito Authentication Redirect.
            //
            // This is required because Cognito needs to get the authentication
            // result from the query string. The parsing is done asynchronously,
            // and the result will be passed to the userHandler. Once the result
            // is parsed, onSuccess userhandler will resolve the promise.
            // console.log('Parsing session');
            auth.parseCognitoWebResponse(href);
            // console.log('Parsed session', session);
        } else {
            console.log('Session not valid and no parsed session');
            // Cognito SDK will handle session refresh / authentication.
            session = auth.getSession();
        }
    });
}
 
export default ensureAuthenticated;